import common from "../../api/common";
import axios from "axios";
import Cookies from "js-cookie";
import axiosApiInstance from "../auth/authService";




export const getPatientProfile = async () => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-patient-profile-secure`,
    data: JSON.stringify({
      email: window.sessionStorage.getItem("email"),
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const getPatientResults = async (data) => {
  let payload = {
    file_to_download: data
  }
  var config = {
    method: "post",
    url: `${common.api_url}/secure-s3-download`,
    data: JSON.stringify(payload),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data
  } else {
    return null;
  }
};
export const getPatientProfileForRefund = async (email) => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-patient-profile-secure`,
    data: JSON.stringify({
      email
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    window.sessionStorage.setItem("email", window.sessionStorage.getItem("email") + " ")
    return null;
  }
};
export const getPatientVisits = async () => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-patient-visits`,
    data: JSON.stringify({
      email: window.sessionStorage.getItem("email"),
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const getPatientVisitsProfile = async () => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-patient-visits`,
    data: JSON.stringify({
      email: window.sessionStorage.getItem("email"),
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const getLabLocations = async (zipcode, testName) => {
  let payload = {
    zip_code: zipcode,
    test_name: testName,
  };
  var config = {
    method: "post",
    url: `${common.api_url}/get-lab-locations`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    data: JSON.stringify(payload),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const getSubscriptionFromDb = async () => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-patient-subcription`,
    data: JSON.stringify({
      email: window.sessionStorage.getItem("email"),
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const getPatientInfo = async (email) => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-patient-info`,
    data: JSON.stringify({
      email: email,
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const getPidValues = async (pid) => {
  var config = {
    method: "get",
    url: `${common.api_url}/get-pid-details?pid=${pid}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const resp = await axios(config);
  return resp.data;

};
export const getInvoice = async (invoice_id) => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-invoice`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify({
      invoice_id: invoice_id,
    }),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const getPatientCurexaOrders = async () => {
  var config = {
    method: "get",
    url: `${common.api_url}/curexa-order-status`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const editEmailPatient = async (email) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-email`,
    data: JSON.stringify({
      old_email: window.sessionStorage.getItem("email"),
      email: email,
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};

export const editCaseId = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-caseid`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};

export const editBackup_medicine = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-backup-medicine`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};

export const editIsSecondCard = async (mrn) => {
  let payload = {
    mrn: mrn,
    card: 1
  }
  var config = {
    method: "post",
    url: `${common.api_url}/visits-add-second-card`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(payload),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};

export const editIsActiveSecondCard = async () => {
  let payload = {
    email: window.sessionStorage.getItem("email"),
    value: 1
  }
  var config = {
    method: "post",
    url: `${common.api_url}/update-is-active-status`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(payload),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const confirmHomeCharge = async (mrn) => {
  let payload = {
    mrn: mrn,
    is_home_charge: 1
  }
  var config = {
    method: "post",
    url: `${common.api_url}/home-charge`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};

export const editFormStatus = async (email, curr_dose) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-form-status`,
    data: JSON.stringify({
      email: email,
      curr_dose: curr_dose
    }),
  };


  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const downloadReq = async () => {
  var config = {
    method: "post",
    url: `${common.api_url}/patient-download-req`,
    data: JSON.stringify({
      email: window.sessionStorage.getItem("email"),
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const cancelVisit = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/patient-canceled-visit`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};

export const approvePatient = async (payload) => {
  var config = {
    method: "post",
    url: `${common.api_url}/approve-customer`,
    data: JSON.stringify(payload),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const editPaymentId = async (mrn, subId) => {
  var config = {
    method: "post",
    url: `${common.api_url}/change-payment-id-with-mrn`,
    data: JSON.stringify({
      subcriptionId: subId,
      mrn: mrn,
    }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const editInusrancePatient = async (email) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-insurance`,
    data: JSON.stringify({ email: email }),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const updateZoomLink = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-zoom-link`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateSchedule_followup_value = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-schedule-followup-visit-col-value`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateSchedule_followup_acutal_value = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-schedule-followup-visit-actual-value`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateSchedule_followup = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-schedule-followup-visit-col`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const saveIntakeStepValue = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/save-intake-step`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const UpdateFirstVisitValue = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/user-set-first-visit`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const UpdateCommerInsuranceValue = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/user-set-commercial-insurance`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const saveRetoolTempRecord = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/create-retool-temporary-record`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateAysncVal = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-is-async`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateAirtable = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-airtable-id`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateInusrancePay = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-is-insurance-pay-status`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const uploadPatientImage = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/upload-patient-image`,
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: data,
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const editPatient = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/edit-patient-profile`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const editPatientNew = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-patient-data`,
    data: JSON.stringify(data),

  };

  const resp = await axiosApiInstance(config);
  if (resp && resp) {
    return resp;
  } else {
    return null;
  }
};
export const uplaodHealthieDocs = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/healthie-create-new-docs`,
    data: JSON.stringify(data),

  };

  const resp = await axiosApiInstance(config);
  if (resp && resp) {
    return resp;
  } else {
    return null;
  }
};
export const editPatientTestName = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-consumer-notes-complaints`,
    data: JSON.stringify(data),

  };

  const resp = await axiosApiInstance(config);
  if (resp && resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const editPatientEmailAddress = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-user-email`,
    data: JSON.stringify(data),

  };

  const resp = await axiosApiInstance(config);
  if (resp && resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const editIsHealthie = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-ishealthie`,
    data: JSON.stringify(data),

  };

  const resp = await axiosApiInstance(config);
  if (resp && resp) {
    return resp.data;
  } else {
    return null;
  }
};

export const editReviewed = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-has-reviewed`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp) {
    return resp;
  } else {
    return null;
  }
};

export const getTaskRespone = async (id) => {

  var config = {
    method: "post",
    url: `${common.api_url}/celery-task-info`,
    data: JSON.stringify(id),

  };

  const resp = await axiosApiInstance(config);
  if (resp.status === 200) {
    return resp;
  } else {
    return 'failure';
  }

};

export const saveSubscription = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/add-subscription`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const addNewCustomer = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/add-new-customer`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const saveSubscriptionType = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/add-subscription-prescriptions`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const editPatientSubscriptionIdAndRefill = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-refill-subscriptionId`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const editPatientRetest = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-retest`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const sendFeedBack = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/send-feedback`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const editPatientAddress = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/edit-patient-address`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateAmazon = async (value) => {
  let payload = {
    value: value,
    email: window.sessionStorage.getItem("email")
  }
  var config = {
    method: "post",
    url: `${common.api_url}/update-amazon-field`,
    data: JSON.stringify(payload),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const sendHerpesMail = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/send-mail-order`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const editPatientMdChronoIds = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-dr-md-id`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const editMembershipPlan = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/change-membership-val`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};

export const getEventWebhooks = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/mdintegrations-webhooks`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const getResults = async (mrn, email) => {
  fetch(
    `https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-results?mrn=${mrn}&email=${email}`
  )
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      // Embed the PDF into the HTML page and show it to the user
      var obj = document.createElement("object");
      obj.style.width = "100%";
      obj.style.height = "842pt";
      obj.type = "application/pdf";
      obj.data = data.data;
      document.body.appendChild(obj);
      // window.open(obj);
      // pdfWindow.document.write(
      //   `<a href=${data.data} download="result.pdf"></a>`
      // );
    });
};



export const SignaturePatient = async (data) => {

  var config = {
    method: "post",
    url: `${common.api_url}/sign-contract`,
    data: JSON.stringify(data),

  };

  const resp = await axiosApiInstance(config);
  if (resp.status === 200) {
    return resp.data;
  } else {
    return 'failure';
  }

};
export const SavePatientFormData = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/add-intake-form-data`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  };

  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const GetPatientFormData = async (id) => {
  var config = {
    method: "get",
    url: `${common.api_url}/get-intake-form-data?browser_id=${id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    }
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const changeBase64toBlob = async (url) => {
  fetch(url)
    .then(res => { return res.blob() })
};

export const CopayStripe = async (copayData, patment_id) => {
  let data1 = {
    customer_id: copayData.customer_id,
    payment_id: patment_id,
    amount: copayData.amount,
    metadata: {
      MDI_fee: 0,
      lab_fee: 0,
      med_fee: 0,
      shipping_fee: 0,
    },
  };
  var config = {
    method: "post",
    url: `${common.api_url}/post-payment-charge`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + " " + window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data1),
  };
  try {
    const resp = await axios(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return "no Data found";
    }
  } catch (e) {
    return e.message;
  }
};

// get-payment-method

export const paymentMethod = async (copayData) => {
  let data1 = {
    customer_id: copayData.customer_id
  };
  var config = {
    method: "post",
    url: `${common.api_url}/get-payment-method`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + " " + window.sessionStorage.getItem("token"),
    },
    data: JSON.stringify(data1),
  };
  try {
    const resp = await axios(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return "no Data found";
    }
  } catch (e) {
    return e.message;
  }
};



export const updatePaProcess = async (data) => {

  var config = {
    method: "post",
    url: `${common.api_url}/update-pa-process-value`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const uploadFileToS3 = async (data) => {

  var config = {
    method: "post",
    url: `${common.api_url}/upload-file`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};


export const updateDrchronoRes = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-drchrono-res`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const updateConsultType = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-consult-value`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const getActiveSeamless = async () => {
  var config = {
    method: "post",
    url: `${common.api_url}/check-active-seamless`,
    data: JSON.stringify({ email: window.sessionStorage.getItem("email") }),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};

export const addRefillData = async (value) => {
  var config = {
    method: "post",
    url: `${common.api_url}/add-user-refill`,
    data: JSON.stringify({ email: window.sessionStorage.getItem("email"),value:value }),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};

export const  removeActiveSeamless = async (subscription_id) => {
  var config = {
    method: "post",
    url: `${common.api_url}/seamlesschex-cancel-subscription-cc`,
    data: JSON.stringify({ subscription_id:subscription_id}),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const patientAddNewCard = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-patient-card`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateDocId = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/add-user-doctor-id`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateDeductibleStatus = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/add-user-decuctible-status`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateCustomerId = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-visit-customer-id`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateInfoNewPayment = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-billing-information-new-payment-method`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateHealthieId = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-healthie-id`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};
export const getCurrentIp = async () => {
  var config = {
    method: "get",
    url: `https://api.ipify.org/?format=json`,
    headers: {
      'Access-Control-Allow-Origin': '*'
  }
    
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};


export const updateIsQuarterly = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-is-quarterly`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};


export const postReferralUrl = async (data) => {

  // Payload example:
  // mrn: str
  // email: str
  // gclid: Optional[str]
  // msclkid: Optional[str]
  // other: Optional[str]
  
  var config = {
    method: "post",
    url: `${common.api_url}/post-user-cookies`,
    data: JSON.stringify(data),
  };

  const resp = await axiosApiInstance(config);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};
